<template>
  <div>
    <div class="banner advertisement-banner">
      <div class="banner-item">{{ item }}</div>
    </div>
    <div class="container">
      <div class="plan">
        <div class="show-title">
          <div class="show-title-t1">线下广告位招租</div>
          <div class="show-title-p">
            <p>
              作为社区生活必需品，小松鼠智能回收机均摆放在各小区核心地带，通过回收机向居民有偿回收各类可回收物。
            </p>
            <p>
              居民通过微信扫码或直接输入手机号，即可快捷地将各类可回收物投递至小松鼠智能回收机，并得到收益。
            </p>
          </div>
          <div class="adv-img"><img :src="advImgUrl" alt="" /></div>
          <div class="row-between">
            <div
              class="adv-btn adv-btn-sp"
              :class="num==0?'adv-btn-sp1':''"
              @click="advImg(0)"
            >
                <div class="row-inline-block adv-btn-sp-img1"></div>
                <div class="row-inline-block">回收机广告位</div>
             
            </div>
            <div
              class="adv-btn adv-btn-sp"
              :class="num==1?'adv-btn-sp2':''"
              @click="
                advImg(1)">
                <div class="row-inline-block adv-btn-sp-img2"></div>
                <div class="row-inline-block">手机广告位置</div>
              
            </div>
          </div>
        </div>
      </div>

      <div class="" style="margin-top: 60px">
        <div class="show-title">
          <div class="show-title-t1">小松鼠广告价格详情页</div>
          <div class="item-color show-title-third">火热招租中</div>
          <div class="show-title-p">
            <p>
              松鼠所入驻的小区地点皆为居民区人流量大、曝光率高的中心区域，如大门口、主路、广场活动中心等，各小区户数为1000-1500
              左右，广告位
            </p>
            <p>日曝光次数为12000-13500次左右。</p>
          </div>
          <div class="item-color adv-unit" style="">
            广告价格（低至10元/天）
          </div>
          <div class="adv-table1">
            <div class="adv-tr row-between adv-tr-sp">
              <div class="adv-td">线下广告</div>
              <div class="adv-td">日（元）</div>
              <div class="adv-td">月（元）</div>
              <div class="adv-td">年（元）</div>
              <div class="adv-td">优惠活动</div>
            </div>
            <div class="adv-tr row-between">
              <div class="adv-td">回收机首页大屏幕</div>
              <div class="adv-td">40</div>
              <div class="adv-td">1000</div>
              <div class="adv-td">10000</div>
              <div class="adv-td adv-td-t1">
                包月赠送2次价值200元的微信顶部推广，<br />包年赠送4次价值2000的微信推广
              </div>
            </div>
            <div class="adv-tr row-between">
              <div class="adv-td">底部轮播广告栏</div>
              <div class="adv-td">15</div>
              <div class="adv-td">300</div>
              <div class="adv-td">3000</div>
              <div class="adv-td adv-td-t1">
                包月赠送2次价值100元的微信底部推广，<br />包年赠送价值300元的小程序2日广告
              </div>
            </div>
          </div>
          <div class="adv-table1">
            <div class="adv-tr row-between adv-tr-sp">
              <div class="adv-td">线下广告</div>
              <div class="adv-td">日（元）</div>
              <div class="adv-td">月（元）</div>
            </div>
            <div class="adv-tr row-between">
              <div class="adv-td">微信小程序/微信公众号/banner</div>
              <div class="adv-td">1000/月</div>
              <div class="adv-td adv-td-t1">
                包月赠送价值100元的7天回收机底部广告，<br />包年赠送价值500元微信推文6次
              </div>
            </div>
            <div class="adv-tr row-between">
              <div class="adv-td">微信定制化广告文章(微信17个社群推广）</div>
              <div class="adv-td">500/次</div>
              <div class="adv-td adv-td-t1" style="font-size: 14px">
                <p>买 4 赠 1</p>
                <p>买 8 赠 2</p>
              </div>
            </div>
            <div class="adv-tr">
              <div class="adv-td" style="text-align: left; text-indent: 30px">
                如需小松鼠定制化设计，根据实际需求在500～1000元左右，消费满5000元设计费用全免
              </div>
            </div>
          </div>
          <div class="item-color show-title-third adv-margin">
            线上广告位招租
          </div>
          <div>
            <div class="adv-ma-p">
              <p>
                上万+活跃用户。是上海本地土生土长公众号平台，品牌效应好覆盖人群广。每条微信推送至上万居民，超强曝光，精准触达用户能瞬间成为朋友
              </p>
              <p>圈中转发的焦点，达到有效快速传播。</p>
            </div>
            <div class="adv-ma-p">
              <p>
                上万+相对于传统的广告营销，成本低，营销手段多元化，支持文字、图片、视频等多种形式！
              </p>
            </div>
          </div>
          <div class="item-color show-title-third adv-margin">线报名须知</div>
          <div>
            <div class="adv-sign">
              <div class="row-inline-block adv-sign-img">
                <img
                  src="../../assets/img/advertisement/adv-icon1.png"
                  alt=""
                />
              </div>
              <div class="row-inline-block adv-sign-t1">报名地点</div>
              <div class="row-inline-block adv-sign-t2">
                各社区、招聘、出（招）租房子，餐饮，教育、地产、通讯等。
              </div>
            </div>
            <div class="row-between">
              <div class="adv-sign">
                <div class="row-inline-block adv-sign-img">
                  <img
                    src="../../assets/img/advertisement/adv-icon2.png"
                    alt=""
                  />
                </div>
                <div class="row-inline-block adv-sign-t1">报名时间</div>
                <div class="row-inline-block adv-sign-t2">2020年6月3日起</div>
              </div>
              <div class="adv-sign">
                <div class="row-inline-block adv-sign-img">
                  <img
                    src="../../assets/img/advertisement/adv-icon3.png"
                    alt=""
                  />
                </div>
                <div class="row-inline-block adv-sign-t1">招租热线</div>
                <div class="row-inline-block adv-sign-t2">187-0190-6918</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="height: 187px"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      item: "广告合作",
      advImgUrl: require("../../assets/img/service-2.png"),
      imgArray: [require('../../assets/img/service-2.png'),require('../../assets/img/advertisement/advertisement-2.png')],
      num:0
    };
  },

  mounted: function () {},
  methods: {
    advImg(e) {
      this.num=e
      this.advImgUrl = this.imgArray[e];
    },
  },
};
</script>

<style>
.advertisement-banner {
  background-image: url("../../assets/img/advertisement/advertisement-banner.png");
}
.adv-img {
  width: 100%;
  height: 685px;
  margin: 30px 0 20px;
}
.adv-btn {
  width: 590px;
  height: 120px;
  line-height: 120px;
  background: #ffffff;
  border: 1px solid #dde8f4;
  text-align: center;
  cursor: pointer;
  font-size: 18px;
  /* font-family: MicrosoftYaHei-Bold, MicrosoftYaHei; */
  font-weight: bold;
  position: relative;
}

.adv-btn-sp1 .adv-btn-sp-img1{
  background: url("../../assets/img/advertisement/adv-a1-active.png") no-repeat center;
}
.adv-btn-sp2 .adv-btn-sp-img2{
  background: url("../../assets/img/advertisement/adv-a2-active.png") no-repeat center;
}
.adv-btn-sp1::before,.adv-btn-sp2::before{
  position: absolute;
  content: '';
  display: block;
  width: 590px!important;
  height: 8px;
  background: linear-gradient(90deg, #0285FF 0%, #02AAFE 100%);
  bottom: 0;
  transition: all 0.3s linear;
  left: -1px;
  
}
.adv-btn-sp1,.adv-btn-sp2{
  color: #0285FF;
}
.adv-btn-sp::before{
  position: absolute;
  content: '';
  display: block;
  width: 0;
  height: 8px;
  background: linear-gradient(90deg, #0285FF 0%, #02AAFE 100%);
  bottom: 0;
  transition: all 0.3s linear;
  left: -1px;
}
.adv-btn-sp:hover:before{
  width: 590px;
  transition: all 0.3s linear;
}
.adv-btn-sp:hover{
  color: #0285ff;
}
.adv-btn-sp-img1,
.adv-btn-sp-img2 {
  margin-right: 10px;
  width: 44px;
  height: 44px;
}
.adv-btn-sp-img1 {
  background: url("../../assets/img/advertisement/adv-a1.png") no-repeat center;
}
.adv-btn-sp-img2 {
  background: url("../../assets/img/advertisement/adv-a2.png") no-repeat center;
}
.adv-btn-sp:hover .adv-btn-sp-img1{
  background: url("../../assets/img/advertisement/adv-a1-active.png") no-repeat center;
}
.adv-btn-sp:hover .adv-btn-sp-img2{
  background: url("../../assets/img/advertisement/adv-a2-active.png") no-repeat center;
}
.adv-unit {
  width: 235px;
  height: 36px;
  border: 1px solid #e9852d;
  text-align: center;
  line-height: 36px;
  margin: 30px 0;
  color: #e9852d;
}
.adv-margin {
  margin: 60px 0 30px;
}
.adv-ma-p {
  position: relative;
  line-height: 28px;
  text-indent: 23px;
  margin: 30px 0;
}
.adv-ma-p::before {
  content: "";
  display: block;
  position: absolute;
  width: 4px;
  background: linear-gradient(180deg, #0285ff 0%, #02aafe 100%);
}
.adv-ma-p:first-child:before {
  height: 44px;
  top: 4px;
  left: 0;
}
.adv-ma-p:nth-child(2)::before {
  height: 22px;
  top: 4px;
  left: 0;
}
.adv-sign {
  width: 100%;
  border: 1px solid #dde8f4;
  line-height: 88px;
  padding-left: 30px;
  /* cursor: pointer; */
  background-position: 0;
  transition: all 0.3s linear;
}
/* .adv-sign:hover {
  background: linear-gradient(90deg, #0285ff 0%, #02aafe 100%);
  background-position: 100% 0;
  color: #fff;
} */
.adv-sign-t1 {
  /* font-family: MicrosoftYaHei-Bold, MicrosoftYaHei; */
  font-weight: bold;
  margin-right: 20px;
}
.adv-sign-img {
  margin-right: 20px;
}
</style>